<div class="product-view" *ngIf="merchant">
  <div class="image">
    <div class="price">{{ utils.formatMoneyByMerchant(product.price, merchant) }}<span class="deposit" *ngIf="product.deposit">+{{ utils.formatMoneyByMerchant(product.deposit, merchant) }}</span></div>
    <img [src]="image" alt="" />
  </div>
  <div class="product-info">
    <div class="name">
      {{ product.name }}
      <span class="weight" *ngIf="product.weight">{{ product.weight }}g</span>
      <span class="weight no-weight" *ngIf="!product.weight">No weight!</span>
    </div>
    <div class="select" (click)="product.weight && emitReplace()" [ngClass]="{ disabled: !product.weight }">
      <span *ngIf="isAdd" >{{ i18n.instant('replace_product.add') }}</span>
      <span *ngIf="!isAdd">{{ i18n.instant('replace_product.select') }}</span>
    </div>
  </div>
</div>
